import type {
  ComponentProps,
  InputHTMLAttributes,
  ReactNode,
  Ref,
} from "react";
import React from "react";
import { compact } from "lodash-es";
import { ariaAttribute } from "../../../utils/attributes";
import { polymorphic } from "../../../utils/ref";
import { styled } from "../../../utils/system/factory";
import type { IconName } from "../../Typography/Icon";
import { Icon } from "../../Typography/Icon";
import { HStack } from "../../Layout/Stack";
import type { Sx, SystemProps } from "../../../utils/types/system";
import type { FlexProps } from "../../Layout/Flex";
import { Flex } from "../../Layout/Flex";
import { useFormFieldContext } from "../FormField/FormFieldProvider";
import { Spinner } from "../../Feedback/Spinner";
import { Body } from "../../Typography/Body";

type CustomInputProps = {
  buttonsElement?: ReactNode;
  "data-form-type"?: string;
  dataTestId?: string;
  hintRight?: ReactNode;
  innerRef?: Ref<HTMLInputElement | null>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  isDisabled?: boolean;
  isErrored?: boolean;
  isLoading?: boolean;
  leftIcon?: IconName;
  onChange: (value: string) => void;
  onChangeEvent?: React.ChangeEventHandler<HTMLInputElement>;
  rightElement?: ReactNode;
};

type HTMLInputProps = Pick<
  ComponentProps<"input">,
  "autoComplete" | "autoFocus" | "placeholder" | "spellCheck" | "type" | "value"
>;

export type BaseInputProps = CustomInputProps &
  HTMLInputProps &
  Omit<SystemProps<"input">, "onChange">;

export const StyledInput = styled<
  "input",
  { hasButtons?: boolean; hasRightElement?: boolean } & Omit<
    BaseInputProps,
    "onChange"
  >
>("input", {
  base: ({ hasButtons, hasRightElement, isErrored, leftIcon, theme }) => {
    const iconWidth = `calc(${theme.spacing[12]} + ${theme.spacing[16]})`;
    const rightElementFullWidth = hasRightElement ? 16 + 12 : 0;

    return [
      {
        backgroundColor: theme.colors.background.feed,
        border: `${theme.spacing[1]} solid ${theme.colors.neutral[30]}`,
        borderColor: isErrored
          ? theme.colors.alert.danger
          : theme.colors.neutral[30],
        borderRadius: theme.radius.base,
        height: theme.spacing[40],
        minHeight: theme.spacing[40],
        outline: "none",
        padding: `${theme.spacing[8]} ${theme.spacing[12]}`,
        paddingLeft: `calc(${leftIcon ? iconWidth : "0px"} + ${
          theme.spacing[12]
        })`,
        paddingRight: `calc(${
          isErrored ? iconWidth : "0px"
        } + ${rightElementFullWidth}px + ${theme.spacing[12]})`,
        width: "100%",
      },
      hasButtons && {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },
      {
        "&:disabled, &[data-disabled]": {
          "&, & ~ .input-buttons": {
            backgroundColor: theme.colors.neutral.light,
            borderColor: theme.colors.neutral[20],
            color: theme.colors.neutral["30"],
            cursor: "not-allowed",
          },
          "&, &::placeholder, & input::placeholder": {
            color: theme.colors.neutral["30"],
          },
        },
      },
      {
        "&:focus-visible, &:focus-within": {
          "& ~ .input-decorators .input-caption": {
            color: theme.colors.neutral.base,
          },
          borderColor: isErrored
            ? theme.colors.alert.danger
            : theme.colors.primary.base,
          boxShadow: `0px 0px 0px 2px ${
            isErrored
              ? theme.colors.alert.dangerLight
              : theme.colors.primary.light
          }`,
          outline: "none",
        },
      },
      {},
      {
        "&:hover:not(:disabled):not([data-disabled])": {
          ...(!isErrored
            ? {
                "& ~ .input-buttons": {
                  "& svg": {
                    color: `${theme.colors.neutral[60]}`,
                  },
                  borderColor: theme.colors.neutral.base,
                },
                borderColor: theme.colors.neutral.base,
              }
            : {}),
        },
      },
    ];
  },
});

const leftIconsSx: Sx = ({ spacing }) => ({
  left: spacing[12],
  position: "absolute",
  top: spacing[12],
});

const rightElementsSx: Sx = ({ spacing }) => ({
  height: spacing[40],
  position: "absolute",
  right: spacing[12],
});

const StyledRelativeContainer = styled("div", {
  base: {
    display: "flex",
    flex: 1,
    position: "relative",
  },
});

const StyledContainer = styled<typeof Flex, FlexProps>(Flex, {
  base: ({ theme }) => ({
    "& input:placeholder-shown": {
      textOverflow: "ellipsis",
    },
    "&[data-disabled='true'] .input-buttons": {
      "& > div": {
        cursor: "not-allowed",
      },
      "& svg": {
        color: theme.colors.neutral["10"],
      },
      borderColor: theme.colors.neutral["20"],
    },
    alignItems: "center",
    position: "relative",
  }),
});

export const BaseInput = polymorphic<"div", BaseInputProps>(
  (
    {
      as,
      autoComplete,
      autoFocus,
      buttonsElement,
      ["data-form-type"]: dataFormType,
      dataTestId,
      hintRight,
      innerRef,
      inputProps,
      isLoading,
      isDisabled: isDisabledProp = isLoading,
      isErrored: isErroredProp,
      leftIcon,
      onBlur,
      onChange: onChangeProp,
      onChangeEvent,
      onClick,
      onFocus,
      onMouseDown,
      placeholder,
      readOnly,
      rightElement,
      spellCheck,
      type,
      value = "",
      ...rest
    },
    ref,
  ) => {
    const context = useFormFieldContext();

    const isDisabled = isDisabledProp || context?.isDisabled;
    const isErrored = isErroredProp || context?.isErrored;

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      if (onChangeEvent) return onChangeEvent(event);

      return onChangeProp(event.target.value);
    };

    return (
      <StyledContainer {...rest} data-disabled={isDisabled} ref={ref}>
        {leftIcon && <Icon isDecorative name={leftIcon} sx={leftIconsSx} />}
        <StyledRelativeContainer>
          <StyledInput
            aria-describedby={context?.feedbackId}
            aria-disabled={ariaAttribute(isDisabled)}
            aria-invalid={ariaAttribute(isErrored)}
            aria-required={ariaAttribute(context?.isRequired)}
            as={as}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className={compact(["field-root", rest.className]).join(" ")}
            data-form-type={dataFormType}
            data-testid={dataTestId}
            hasButtons={Boolean(buttonsElement)}
            hasRightElement={Boolean(rightElement)}
            id={context?.id}
            leftIcon={leftIcon}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onMouseDown={onMouseDown}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={innerRef}
            spellCheck={spellCheck}
            type={type}
            value={value}
            {...inputProps}
            disabled={isDisabled}
            isErrored={isErrored}
          />
          {Boolean(rightElement || isErrored || isLoading || hintRight) && (
            <HStack
              alignItems="center"
              className="input-decorators"
              spacing={12}
              sx={rightElementsSx}
            >
              {isLoading && <Spinner isActive size="sm" />}
              {hintRight && (
                <Body
                  className="input-caption"
                  isInline
                  isSecondary
                  size="sm"
                  variant="medium"
                >
                  {hintRight}
                </Body>
              )}
              {rightElement}
              {isErrored && (
                <Icon
                  name="error-circle"
                  sx={({ colors }) => ({ color: colors.alert.danger })}
                />
              )}
            </HStack>
          )}
        </StyledRelativeContainer>
        {buttonsElement}
      </StyledContainer>
    );
  },
);

BaseInput.displayName = "BaseInput";
