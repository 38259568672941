import React from "react";
import { polymorphic } from "../../utils/ref";
import type { FlexProps } from "../Layout/Flex";
import { Flex } from "../Layout/Flex";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";
import { generateSpacing } from "../../theme/commons";
import {
  PersonAvatar,
  PersonDescription,
  PersonName,
} from "./Person/PersonComponents";

type Props = {
  isInline?: boolean;
} & FlexProps;

export type PersonProps = Props & SystemProps<"div">;

type Compound = {
  Avatar: typeof PersonAvatar;
  Description: typeof PersonDescription;
  Name: typeof PersonName;
};

const StyledPerson = styled<typeof Flex, { isInline?: boolean }>(Flex, {
  base: ({ isInline, theme }) => [
    generateSpacing(theme)(12, "left"),
    isInline && {
      "& > div:not(.avatar)": {
        marginTop: "-0.25rem",
      },
      ".description": {
        fontSize: theme.font.size.sm,
        marginLeft: theme.spacing[8],
      },
    },
  ],
});

export const Person = polymorphic<"div", Props, Compound>(
  ({ isInline, ...rest }, ref) => (
    <StyledPerson
      {...rest}
      alignItems={isInline ? "center" : undefined}
      isInline={isInline}
      ref={ref}
    />
  ),
);

Person.displayName = "Person";
Person.Avatar = PersonAvatar;
Person.Description = PersonDescription;
Person.Name = PersonName;
