import { indexOf } from "lodash-es";
import type { DbPricingSteps } from "../../sharedTypes";

type Category = {
  value: DbPricingSteps;
};

export const categoriesSortedArray: DbPricingSteps[] = [
  "departure_truck_freight",
  "departure_fees",
  "departure_customs",
  "freight",
  "arrival_fees",
  "arrival_truck_freight",
  "insurance",
  "customs",
  "other",
  "other_services",
  "carbon_offset",
  "arrival_customs",
];

export const comparePriceCategories = (
  a: DbPricingSteps | null,
  b: DbPricingSteps | null,
) => indexOf(categoriesSortedArray, a) - indexOf(categoriesSortedArray, b);

export const sortPriceCategories = <T extends Category>(
  categories: T[],
): T[] => {
  return categories
    .slice()
    .sort(
      (a, b) =>
        categoriesSortedArray.indexOf(a.value) -
        categoriesSortedArray.indexOf(b.value),
    );
};
