import type { DbPricingSteps } from "../../sharedTypes";
import { compareOvrseaNames } from "./compareOvrseaNames";

const ESTIMATED_PRICE_OVRSEA_NAME = "Estimated Price";
const FREIGHT_OVRSEA_NAME = "Freight";
const PRECARRIAGE = "Pre-carriage";
const ONCARRIAGE = "On-carriage";
const PICKUP = "Pickup";
const DELIVERY = "Delivery";
const FSC = "FSC";
const SSC = "SSC";
const ADD_ON = "Add On";
const PROFIT_SHARE = "Profit Share";

type MarginType = "at_cost" | "variable";

const priceIsInsurance = (category: DbPricingSteps) => category === "insurance";
const priceIsTruckMainPrice = (
  ovrseaName: string,
  category: DbPricingSteps,
) => {
  const priceIsDepartureTruckFreight =
    category === "departure_truck_freight" &&
    (compareOvrseaNames(ovrseaName, PRECARRIAGE) ||
      compareOvrseaNames(ovrseaName, PICKUP));
  const priceIsArrivalTruckFreight =
    category === "arrival_truck_freight" &&
    (compareOvrseaNames(ovrseaName, ONCARRIAGE) ||
      compareOvrseaNames(ovrseaName, DELIVERY));

  return priceIsDepartureTruckFreight || priceIsArrivalTruckFreight;
};
const priceIsFromWebcargo = (ovrseaName: string) =>
  [ADD_ON, FSC, SSC].includes(ovrseaName);
const priceIsFreight = (ovrseaName: string, category: DbPricingSteps) =>
  compareOvrseaNames(ovrseaName, FREIGHT_OVRSEA_NAME) && category === "freight";
const priceIsProfitShare = (ovrseaName: string) =>
  compareOvrseaNames(ovrseaName, PROFIT_SHARE);
const priceIsEstimated = (ovrseaName: string) =>
  compareOvrseaNames(ovrseaName, ESTIMATED_PRICE_OVRSEA_NAME);

export const determineMarginTypeFromNameAndCategory = ({
  category,
  ovrseaName,
  purchasePriceCentsInPriceCurrency,
}: {
  category: DbPricingSteps;
  ovrseaName: string;
  purchasePriceCentsInPriceCurrency: number;
}): MarginType | null => {
  // Prices with negative purchase price cents are considered as at_cost prices
  if (purchasePriceCentsInPriceCurrency < 0) {
    return "at_cost";
  }
  if (priceIsInsurance(category)) {
    return "variable";
  }
  if (priceIsEstimated(ovrseaName)) {
    return "variable";
  }
  if (priceIsTruckMainPrice(ovrseaName, category)) {
    return "variable";
  }
  if (priceIsFromWebcargo(ovrseaName)) {
    return "variable";
  }
  if (priceIsFreight(ovrseaName, category)) {
    return "variable";
  }
  if (priceIsProfitShare(ovrseaName)) {
    return "at_cost";
  }

  return null;
};
