import type { DbPricingSteps } from "../sharedTypes";

type MinimalPrice = {
  category: DbPricingSteps;
  name?: string;
  ovrseaName?: string;
};

const isOvrseaNameFreight = <Price extends MinimalPrice>(price: Price) =>
  price.category === "freight" &&
  price.ovrseaName &&
  (price.ovrseaName.toLowerCase().includes("freight") ||
    price.ovrseaName.toLowerCase().includes("fret"));

const isClientNameFreight = <Price extends MinimalPrice>(price: Price) =>
  price.category === "freight" &&
  price.name &&
  (price.name.toLowerCase().includes("freight") ||
    price.name.toLowerCase().includes("fret"));

export const findFreightPrice = <Price extends MinimalPrice>(
  pricesArray: Price[],
) => {
  const freightPriceBasedOnOvrseaName = pricesArray.find(isOvrseaNameFreight);
  const freightPriceBasedOnClientName = pricesArray.find(isClientNameFreight);

  return freightPriceBasedOnOvrseaName ?? freightPriceBasedOnClientName;
};
