import React from "react";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import type { SystemProps } from "../../utils/types/system";

type Props = {
  isInline?: boolean;
};

export type AmountProps = Props & SystemProps<"div">;

const StyledAmount = styled("span", {
  base: ({ theme }) => ({
    fontVariantNumeric: "tabular-nums",
    fontWeight: theme.font.weight.medium,
  }),
});

export const Amount = polymorphic<"div", Props>(
  ({ children, isInline = true, ...rest }, ref) => {
    const Component = isInline ? "span" : "div";

    return (
      <StyledAmount as={Component} {...rest} ref={ref}>
        {children}
      </StyledAmount>
    );
  },
);

Amount.displayName = "Amount";
