import { merge } from "lodash-es";
import type { Theme } from "../../theme/theme";
import { spacingProps } from "../system/spacing";

export type SpacingScale = keyof {
  [key in keyof Theme["spacing"]]: Theme["spacing"][key];
};

export type StyleSystemProps = Partial<
  Record<keyof typeof spacingProps, SpacingScale>
>;

export type StyleSystemKeys = "spacing";

export const styleSystemProps = merge({}, spacingProps);
