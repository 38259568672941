export const LUDOVIC_ONECHAIN_USER_ID = "12459";
export const PIERRE_PAUVERT_ONECHAIN_USER_ID = "12403";

export const emailsAuthorizedOnTms = [
  "achraf.laamoum@ovrsea.com",
  "achraf.laamoum+hbi@ovrsea.com",
  "alodie.boissonnet@ovrsea.com",
  "alodie.boissonnet+carter@ovrsea.com",
  "alodie.boissonnet+hbi@ovrsea.com",
  "antoine@ovrsea.com",
  "antoine+carter@ovrsea.com",
  "baudouin@ovrsea.com",
  "brieuc@ovrsea.com",
  "donatella.delinares@ovrsea.com",
  "etienne.grall@ovrsea.com",
  "etienne.grall+ff@ovrsea.com",
  "georges@ovrsea.com",
  "lea.gilles@ovrsea.com",
  "lea.gilles+hbi@ovrsea.com",
  "lea.gilles+warehouse@onechain-tms.com",
  "moaad.fattali@ovrsea.com",
  "nicolas@ovrsea.com",
  "nicolas+ff@ovrsea.com",
  "paul@ovrsea.com",
  "remy.vandyk@ovrsea.com",
  "ludovic.garnier@ovrsea.com",
  "hugo.weber@ovrsea.com",

  // TO BE DELETED ONCE ARTHUR ASKS FOR IT
  "arthur@ovrsea.com",
];

// TODO CHRISTOFLE - This will be replaced by user teams
export const emailsAuthorizedOnOrderPreparation = [
  "a.knight@christofle.com",
  "f.Limoli@christofle.com",
  "jc.polidoro@christofle.com",
  "m.hansen@christofle.com",
  "p.morales@christofle.com",
  "rmendella@christofle.com",
  "v.rosa@christofle.com",
  "w.wang@christofle.com",
  "c.sui@christofle.com",
  "e.tio@christofle.com",
  "j.zou@christofle.com",
  "a.otsuka@christofle.com",
  "c.diaz@christofle.com",
  "c.bruneau@christofle.com",
  "c.fave@christofle.com",
  "ch.bourgoin@christofle.com",
  "f.delahoulliere@christofle.com",
  "i.mongali@christofle.com",
  "j.amourette@christofle.com",
  "m.savoye@christofle.com",
  "p.waszak@christofle.com",
  "r.lizarraga@christofle.com",
  "v.landeau@christofle.com",
  "z.chiguer@christofle.com",
  "j.gomis@christofle.com",
  "y.mimuro@christofle.com",
  "y.okawara@christofle.com",
  "m.tanaka@christofle.com",
  "f.gedda@christofle.com",
  "d.monteiro@christofle.com",
  "a.otsuka@christofle.com",
  "y.mimuro@christofle.com",
  "f.limoli@christofle.com",
  "rmendella@christofle.com",
  "c.sui@christofle.com",
  "j.zou@christofle.com",
  "s.huang@christofle.com",
  "w.wang@christofle.com",
  "jc.polidoro@christofle.com",
  "v.rosa@christofle.com",

  "ludovic.garnier@ovrsea.com",
  "pierre.pauvert@onechain-tms.com",
  "ludovic.garnier@onechain-tms.com",
  "lea.gilles@ovrsea.com",
  "achraf.laamoum@ovrsea.com",
  "etienne.grall@ovrsea.com",
  "alodie.boissonnet@ovrsea.com",
  "nicolas@ovrsea.com",
  "hugo.weber@ovrsea.com",
  "baudouin@ovrsea.com",
];

export const CUSTOMER_SUCCESS_EMAILS = [
  "lea.gilles@ovrsea.com",
  "lea.gilles@onechain-tms.com",
  "ludovic.garnier@ovrsea.com",
  "ludovic.garnier@onechain-tms.com",
  "j.gomis@christofle.com",
];

export const GINGER_EMAILS = [
  "lea.gilles@ovrsea.com",
  "achraf.laamoum@ovrsea.com",
  "etienne.grall@ovrsea.com",
  "alodie.boissonnet@ovrsea.com",
  "hugo.weber@ovrsea.com",
];

export const DEV_EMAILS = [
  "alodie.boissonnet@ovrsea.com",
  "alodie.boissonnet+carter@ovrsea.com",
  "achraf.laamoum@ovrsea.com",
  "achraf.laamoum+hbi@ovrsea.com",
  "etienne.grall@ovrsea.com",
  "etienne.grall+ff@ovrsea.com",
  "antoine.huguet@ovrsea.com",
  "remy.vandyk@ovrsea.com",
  "antoine@ovrsea.com",
  "nicolas@ovrsea.com",
  "hugo.weber@ovrsea.com",
];

export const PAPRIKA_EMAILS = [
  "moaad.fattali@ovrsea.com",
  "donatella.delinares@ovrsea.com",
  "antoine.huguet@ovrsea.com",
  "remy.vandyk@ovrsea.com",
];

export const OPS_SPECIALISTS_AND_PRICING_EXPERTS_AND_TLO = [
  "corentin.charachon@ovrsea.com",
  "louis.gas@ovrsea.com",
  "alais.renard@ovrsea.com",
  "amandine.belval@ovrsea.com",
  "louis.guizien@ovrsea.com",
  "heloise@ovrsea.com",
  "paul.henry@ovrsea.com",
  "mariealix.lemee@ovrsea.com",
  "vianney.martin@ovrsea.com",
  "hugo.debengy@ovrsea.com",
  "lea.boubet@ovrsea.com",
  "margaux@ovrsea.com",
  "solenne.michel@ovrsea.com",
];

export const employeeEmailsAuthorizedOnNewTariffNumberFeatures = [
  "ludovic.garnier@ovrsea.com",
  "georges@ovrsea.com",
  "brieuc@ovrsea.com",
  "nader.zaim@ovrsea.com",
  "remy.vandyk@ovrsea.com",
  "antoine@ovrsea.com",
];
