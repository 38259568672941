import { shipownersBelongToTheSameFamily } from "../../operations/shipowners/shipownersBelongToTheSameFamily";
import roundValue from "../../roundValue";
import type { DbPricingSteps } from "../../sharedTypes";
import { compareOvrseaNames } from "../pricesUtils/compareOvrseaNames";
import type { Currency } from "../pricesUtils/priceUtils";

export type ExtractedPriceStatus =
  | "DIFFERENT_AMOUNT_FROM_FINAL_CHECK"
  | "MATCHING_FINAL_CHECK_AMOUNT"
  | "NOT_EXISTING_IN_FINAL_CHECK"
  | "OVRSEA_NAME_OR_CATEGORY_NOT_SPECIFIED";

// We say that an extracted price corresponds to a final check price if the following conditions are met:
// - The extracted price has a supplier belonging to the same family as the shipowner of the final check price
// - The extracted price has an ovrseaName that matches the ovrseaName of the final check price
// - The extracted price has a category that matches the category of the final check price
export const extractedPriceCorrespondsToFinalCheckPrice =
  (extractedPrice: {
    categoryMatchedByEmployee: DbPricingSteps | null;
    ovrseaNameMatchedByEmployee: string;
    supplier: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }) =>
  (finalCheckPrice: {
    category: DbPricingSteps;
    priceName: string;
    shipowner: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }) => {
    return (
      finalCheckPrice.shipowner &&
      extractedPrice.supplier &&
      shipownersBelongToTheSameFamily(
        finalCheckPrice.shipowner,
        extractedPrice.supplier,
      ) &&
      compareOvrseaNames(
        finalCheckPrice.priceName,
        extractedPrice.ovrseaNameMatchedByEmployee,
      ) &&
      finalCheckPrice.category === extractedPrice.categoryMatchedByEmployee
    );
  };

// The difference with above function is that this function checks for an exact match on ovrseaNames.
// This is needed becasue the function compareOvrseaNames is too permissive and can match ovrseaNames that are not exactly the same (Freight & Freight Surcharge for instance)
// This can lead to bug like https://ovrsea.slack.com/archives/CF477A161/p1720084106482279
export const extractedPriceExactlyCorrespondsToFinalCheckPrice =
  (extractedPrice: {
    categoryMatchedByEmployee: DbPricingSteps | null;
    ovrseaNameMatchedByEmployee: string;
    supplier: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }) =>
  (finalCheckPrice: {
    category: DbPricingSteps;
    priceName: string;
    shipowner: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }) => {
    return (
      finalCheckPrice.shipowner &&
      extractedPrice.supplier &&
      shipownersBelongToTheSameFamily(
        finalCheckPrice.shipowner,
        extractedPrice.supplier,
      ) &&
      finalCheckPrice.priceName.trim() ===
        extractedPrice.ovrseaNameMatchedByEmployee.trim() &&
      finalCheckPrice.category === extractedPrice.categoryMatchedByEmployee
    );
  };

export const extractedPriceMatchFinalCheckPriceAmount =
  (extractedPrice: {
    amountInCents: number;
    categoryMatchedByEmployee: DbPricingSteps | null;
    currencyMatchedByEmployee: Currency | null;
    ovrseaNameMatchedByEmployee: string;
    supplier: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }) =>
  (finalCheckPrice: {
    category: DbPricingSteps;
    priceCurrency: Currency;
    priceName: string;
    priceQuantity: number;
    shipowner: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
    unitPrice: number;
  }) => {
    return (
      roundValue(
        finalCheckPrice.unitPrice * finalCheckPrice.priceQuantity * 100,
      ) === extractedPrice.amountInCents &&
      finalCheckPrice.priceCurrency === extractedPrice.currencyMatchedByEmployee
    );
  };

export const priceIsFullForFinalCheckReconciliation = <
  Price extends {
    categoryMatchedByEmployee: DbPricingSteps | null;
    currencyMatchedByEmployee: Currency | null;
    ovrseaNameMatchedByEmployee: null | string;
  },
>(
  price: Price,
): price is {
  categoryMatchedByEmployee: DbPricingSteps;
  currencyMatchedByEmployee: Currency;
  ovrseaNameMatchedByEmployee: string;
} & Price => {
  return Boolean(
    price.currencyMatchedByEmployee &&
      price.ovrseaNameMatchedByEmployee &&
      price.categoryMatchedByEmployee,
  );
};

export const computeExtractedPriceStatus =
  ({
    finalCheckPrices,
  }: {
    finalCheckPrices: {
      category: DbPricingSteps;
      priceCurrency: Currency;
      priceName: string;
      priceQuantity: number;
      shipowner: {
        id: string;
        parentShipownerGroup: { id: string } | null;
      } | null;
      unitPrice: number;
    }[];
  }) =>
  (extractedPrice: {
    amountInCents: number;
    categoryMatchedByEmployee: DbPricingSteps | null;
    currencyMatchedByEmployee: Currency | null;
    ovrseaNameMatchedByEmployee: null | string;
    supplier: {
      id: string;
      parentShipownerGroup: { id: string } | null;
    } | null;
  }): ExtractedPriceStatus => {
    if (!priceIsFullForFinalCheckReconciliation(extractedPrice)) {
      return "OVRSEA_NAME_OR_CATEGORY_NOT_SPECIFIED";
    }

    const finalCheckPriceCorrespondingToExtractedPrice =
      finalCheckPrices.find(
        extractedPriceExactlyCorrespondsToFinalCheckPrice(extractedPrice),
      ) ??
      finalCheckPrices.find(
        extractedPriceCorrespondsToFinalCheckPrice(extractedPrice),
      );

    if (!finalCheckPriceCorrespondingToExtractedPrice) {
      return "NOT_EXISTING_IN_FINAL_CHECK";
    }

    if (
      extractedPriceMatchFinalCheckPriceAmount(extractedPrice)(
        finalCheckPriceCorrespondingToExtractedPrice,
      )
    ) {
      return "MATCHING_FINAL_CHECK_AMOUNT";
    }

    return "DIFFERENT_AMOUNT_FROM_FINAL_CHECK";
  };
