export const KRONOS_LIBRE_CLIENT_ID = "84";

export const KSGB_CLIENT_ID = "902";

export const FONROCHE_CLIENT_ID = "1908";

export const ARIZONA_APPAREL_CLIENT_ID = "5348";
export const TMS_SANDBOX_CLIENT_ID = "5695";
export const CHRISTOFLE_CLIENT_ID = "1291";
export const CHRISTOFLE_LATAM_CLIENT_ID = "9599";
export const CHRISTOFLE_ASIA_CLIENT_ID = "9602";
export const CHRISTOFLE_BRAZIL_CLIENT_ID = "9653";
export const CHRISTOFLE_JAPAN_CLIENT_ID = "2177";
export const CHRISTOFLE_SILVER_CLIENT_ID = "1392";
export const RHENUS_CLIENT_ID = "7159";
export const MR_BRICOLAGE_CLIENT_ID = "4461";
export const ZADIG_VOLTAIRE_CLIENT_ID = "2673";
export const BOLLE_HOLDING_CLIENT_ID = "5642";
export const BOLLE_SAFETY_CLIENT_ID = "2952";
export const BOLLE_BRANDS_CLIENT_ID = "3465";
export const NV_GALLERY_CLIENT_ID = "296";
export const CLAREO_CLIENT_ID = "4044";
export const THE_KOOPLES_CLIENT_ID = "2523";
export const COLORCON_CLIENT_ID = "2496"; // Airnov
export const COLD_AND_CO_CLIENT_ID = "3247";
export const LE_COQ_SPORTIF_CLIENT_ID = "2749";
export const DREAMPATH_CLIENT_ID = "5015";
export const BRIOCHE_PASQUIER_CLIENT_ID = "5698";
export const SOLIDO_CLIENT_ID = "2230";
