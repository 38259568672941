export const DIDI_LOGISTIC_AGENT_ID = "aaf75ab2-3fba-4780-9994-d576cda5ce7b";
export const HERPORT_LOGISTIC_AGENT_ID = "047631b0-6b0e-4dee-865d-cf0dd7a42024";
export const YUSEN_LOGISTIC_AGENT_ID = "fe360c4b-0e82-4ea5-98c1-b55a52e7aef5";
export const ZIEGLER_LOGISTIC_AGENT_ID = "b03c802a-5472-4a9d-8b1d-d6d0054f23c5";
export const UPS_LOGISTIC_AGENT_ID = "4aba51a8-4f88-4dbb-b7f6-73db8bc3347e";
export const KUEHNE_LOGISTIC_AGENT_ID = "6f21212a-a82b-4da8-9934-61a6bf368868";
export const GEODIS_LOGISTIC_AGENT_ID = "57658faf-8471-42bf-8dad-77dfdbc88281";
export const SCHNEIDER_LOGISTIC_AGENT_ID =
  "c8e23db7-f1b2-4977-ae31-ca7b4428ad9e";
export const CLASQUIN_LOGISTIC_AGENT_ID =
  "8107386c-3df0-499e-aadb-c9e7eab88de5";
export const SEKO_BANSARD_LOGISTIC_AGENT_ID =
  "7cdf9686-7fd9-4b0a-89cd-af2c2ac1d4ba";
export const FATTON_LOGISTIC_AGENT_ID = "e29e5df7-fe75-4f74-9b49-d9a5a6332fa5";

export const CARTER_LOGISTIC_AGENT_ID = "a31bdcce-b32c-4526-be79-80193b7b2097";
export const DELTA_TRANSIT_LOGISTIC_AGENT_ID =
  "794c3a82-5530-442e-93dc-be18705be654";

export const FREIGHT_FORWARDERS_IDS_ALLOWED_TO_USE_QUOTE_EXTRACTION = [
  ZIEGLER_LOGISTIC_AGENT_ID,
  SCHNEIDER_LOGISTIC_AGENT_ID,
  UPS_LOGISTIC_AGENT_ID,
  DELTA_TRANSIT_LOGISTIC_AGENT_ID,
  CARTER_LOGISTIC_AGENT_ID,
  YUSEN_LOGISTIC_AGENT_ID,
  SEKO_BANSARD_LOGISTIC_AGENT_ID,
  GEODIS_LOGISTIC_AGENT_ID,
];

export const LOGISTIC_AGENTS_IDS_ALLOWED_TO_USE_INVOICE_EXTRACTION = [
  ZIEGLER_LOGISTIC_AGENT_ID,
  CLASQUIN_LOGISTIC_AGENT_ID,
  FATTON_LOGISTIC_AGENT_ID,
  DELTA_TRANSIT_LOGISTIC_AGENT_ID,
  CARTER_LOGISTIC_AGENT_ID,
];
