import React from "react";
import { isDefined } from "@ovrsea/ovrutils";
import type { SpacingScale } from "../../utils/system/system";
import { polymorphic } from "../../utils/ref";
import type { SystemProps } from "../../utils/types/system";
import { styled } from "../../utils/system/factory";

type DividerOrientation = "horizontal" | "vertical";

type Props = {
  orientation?: DividerOrientation;
  spacing?: SpacingScale;
};

export type DividerProps = Props & SystemProps<"hr">;

const StyledHr = styled<"hr", Props>("hr", {
  base: ({ orientation, spacing, theme }) => [
    {
      borderColor: theme.colors.neutral["20"],
      borderStyle: "solid",
      boxSizing: "content-box",
      height: "100%",
      margin: 0,
      overflow: "visible",
    },
    {
      borderWidth: orientation === "vertical" ? "0 0 0 1px" : "0 0 1px",
      height: orientation === "vertical" ? "100%" : 0,
      width: orientation === "vertical" ? 0 : "100%",
    },
    orientation === "vertical" &&
      isDefined(spacing) && {
        marginLeft: theme.spacing[spacing],
        marginRight: theme.spacing[spacing],
      },
    orientation === "horizontal" &&
      isDefined(spacing) && {
        marginBottom: theme.spacing[spacing],
        marginTop: theme.spacing[spacing],
      },
  ],
});

export const Divider = polymorphic<"hr", Props>(
  ({ orientation = "horizontal", spacing, ...rest }, ref) => (
    <StyledHr
      aria-orientation={orientation}
      orientation={orientation}
      ref={ref}
      spacing={spacing}
      {...rest}
    />
  ),
);

Divider.displayName = "Divider";
