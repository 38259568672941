/* eslint-disable perfectionist/sort-objects */
import type { SyntheticEvent } from "react";
import React from "react";
import { polymorphic } from "../../../utils/ref";
import { ScaleMotion } from "../../Meta/ScaleMotion";
import { Overlay } from "../Overlay";
import { styled } from "../../../utils/system/factory";
import { ariaAttribute } from "../../../utils/attributes";
import type { ButtonGroupProps } from "../../Action/ButtonGroup";
import { ButtonGroup } from "../../Action/ButtonGroup";
import { useModalContext } from "./modalContext";

const StyledHeader = styled("header", {
  base: ({ theme }) => ({
    lineHeight: 1.5,
    padding: "16px 24px",
    fontSize: theme.font.size.lg,
    fontWeight: 500,
    flex: "0 1 0%",
  }),
});

const StyledBody = styled("div", {
  base: {
    padding: "8px 24px",
    overflow: "auto",
    flex: "1 1 0%",
  },
});

const MODAL_CONTENT_HEIGHT = "100vh";
const MODAL_CONTENT_OFFSET = "7.5rem";
const MODAL_HEADER_HEIGHT = "62px";
const MODAL_FOOTER_HEIGHT = "68px";

const MODAL_BODY_MAX_HEIGHT = `calc(${MODAL_CONTENT_HEIGHT} - ${MODAL_CONTENT_OFFSET} - ${MODAL_HEADER_HEIGHT} - ${MODAL_FOOTER_HEIGHT})`;

const modalSizes = {
  sm: 550,
  md: 700,
  lg: 850,
  xl: 1000,
  "2xl": 1150,
  fullscreen: 1450,
};

export type ModalSize = keyof typeof modalSizes;

const StyledScaleMotion = styled<typeof ScaleMotion, { size: ModalSize }>(
  ScaleMotion,
  {
    base: ({ size, theme }) => ({
      maxWidth: modalSizes[size],
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
      outline: "transparent solid 2px",
      outlineOffset: "2px",
      borderRadius: theme.radius.lg,
      background: theme.colors.background.light,
      color: "inherit",
      marginTop: "60px",
      marginBottom: "60px",
      zIndex: theme.zIndex.modal,
      boxShadow: theme.shadows.lg,
      opacity: 1,
      transform: "none",
      maxHeight: `calc(${MODAL_CONTENT_HEIGHT} - ${MODAL_CONTENT_OFFSET})`,
    }),
  },
);

const StyledOverlay = styled(Overlay, {
  base: {
    "@supports (height: -webkit-fill-available)": {
      height: "-webkit-fill-available",
    },
  },
});

const StyledModalFooter = styled<typeof ButtonGroup, ButtonGroupProps>(
  ButtonGroup,
  {
    base: {
      padding: "16px 24px",
    },
  },
);

const ModalFooter = polymorphic<"footer">((props, ref) => (
  <StyledModalFooter
    alignItems="center"
    as="footer"
    id="modal-header"
    justifyContent="flex-end"
    ref={ref}
    {...props}
  />
));

ModalFooter.displayName = "ModalFooter";

const ModalHeader = polymorphic<"header">((props, ref) => (
  <StyledHeader id="modal-header" ref={ref} {...props} />
));

ModalHeader.displayName = "ModalHeader";

const ModalBody = polymorphic<"div">((props, ref) => (
  <StyledBody id="modal-body" ref={ref} {...props} />
));

ModalBody.displayName = "ModalBody";

const ModalContent = polymorphic<"div">((props, ref) => {
  const {
    onClose,
    shouldCloseOnEscape,
    shouldCloseOnOutsideClick,
    size = "md",
  } = useModalContext();

  return (
    <StyledOverlay
      isOpened
      onClose={onClose}
      shouldCloseOnEscape={shouldCloseOnEscape}
      shouldCloseOnOutsideClick={shouldCloseOnOutsideClick}
      shouldTrapFocus
    >
      <StyledScaleMotion
        aria-describedby="modal-body"
        aria-labelledby="modal-header"
        aria-modal={ariaAttribute(true)}
        onMouseDown={(event: SyntheticEvent) => event.stopPropagation()}
        ref={ref}
        role="dialog"
        size={size}
        tabIndex={-1}
        {...props}
      />
    </StyledOverlay>
  );
});

ModalContent.displayName = "ModalContent";

export {
  MODAL_BODY_MAX_HEIGHT,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  modalSizes,
};
