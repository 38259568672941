import React from "react";
import { polymorphic } from "../../../utils/ref";
import { Avatar } from "../Avatar";
import type { BoxProps } from "../../Meta/Box";
import { styled } from "../../../utils/system/factory";

export const PersonAvatar = Avatar;

export const PersonName = styled("div", {
  base: ({ theme }) => ({
    fontWeight: theme.font.weight.medium,
  }),
});

const StyledDescription = styled("div", {
  base: ({ theme }) => ({
    color: theme.colors.text.secondary,
  }),
});

export const PersonDescription = polymorphic<"div", BoxProps>((props, ref) => (
  <StyledDescription className="description" ref={ref} {...props} />
));

PersonAvatar.displayName = "PersonAvatar";
PersonName.displayName = "PersonName";
PersonDescription.displayName = "PersonDescription";
