import { first, groupBy, sumBy } from "lodash-es";
import { OvrseaDataError } from "../errors/OvrseaDataError";
import roundValue from "../roundValue";
import type { FreightMethodOvrUtils } from "../orderUtils/orderFreightMethodSelectors";
import type { EnumOrLiteral } from "../typescriptHelpers/enumOrTypeLiteral";
import { isDefined } from "../other/isDefined";
import { isNotDefined } from "../other/isNotDefined";
import {
  convertCbfToCbm,
  convertCmToIn,
  convertInToCm,
  convertLbsToKg,
} from "./../conversions";
import type { CalculateTotalSpecificUnit } from "./interfaces";
import { computeTotalWeight } from "./loadUtils/computeTotalWeight";
import { computeTotalVolume } from "./loadUtils/computeTotalVolume";

export type LoadType =
  | "colis"
  | "full_truck"
  | "old"
  | "palletized_80_120"
  | "palletized_100_120"
  | "palletized_specific"
  | "solid_box"
  | "vrac";

export type UnitSystem = "imperial" | "metric";

export type LoadOvrutilsForm = {
  loadType: LoadType;
  totalVolume?: null | number | string;
  totalWeight?: null | number | string;
  unitHeight?: null | number | string;
  unitLength?: null | number | string;
  unitNumber?: null | number | string;
  unitSystem?: UnitSystem | null;
  unitWeight?: null | number | string;
  unitWidth?: null | number | string;
  volumeUnit?: null | string;
  weightUnit?: null | string;
};

export type VolumeUnit = "cm" | "ft" | "in" | "m";
export type WeightUnit = "kg" | "lbs";
export type VolumeUnitFullName = "centimeter" | "feet" | "inch" | "meter";
export type WeightUnitFullName = "kilogram" | "pound";

export type LoadOvrutils = {
  hazardous?: boolean | null;
  hazardousDetails?: null | string;
  lithium?: boolean | null;
  loadType: EnumOrLiteral<LoadType>;
  magnetic?: boolean | null;
  nonStackable?: boolean | null;
  refrigerated?: boolean | null;
  totalVolume?: null | number;
  totalWeight?: null | number;
  unitHeight?: null | number;
  unitLength?: null | number;
  unitNumber?: null | number;
  unitWeight?: null | number;
  unitWidth?: null | number;
};

export type LoadOvrutilsWithUnits = {
  volumeUnit: VolumeUnit;
  weightUnit: WeightUnit;
} & LoadOvrutils;

export type LoadsChange = {
  resultA: null | number;
  resultB: null | number;
  type: string;
};

export const GENERIC_NON_STACKABLE_PALLET_HEIGHT_IN_CM = 230;
export const GENERIC_PALLET_VOLUME_IN_M3 = 1.584;
export const GENERIC_PALLET_WEIGHT_IN_KG = 1000;
export const GENERIC_PALLET_HEIGHT_IN_CM = 155;
export const GENERIC_PALLET_LENGTH_IN_CM = 120;
export const GENERIC_PALLET_WIDTH_IN_CM = 80;
export const MAX_PALLET_HEIGHT_FOR_NON_STACKABLE_OCEAN_IN_CM = 180;
export const UNIT_SYSTEMS: {
  [key in UnitSystem]: {
    volumeUnit: "cm" | "in";
    weightUnit: "kg" | "lbs";
  };
} = {
  imperial: {
    volumeUnit: "in",
    weightUnit: "lbs",
  },
  metric: {
    volumeUnit: "cm",
    weightUnit: "kg",
  },
};

export const mapperVolumeUnitShortForm: {
  [key in VolumeUnit]: VolumeUnitFullName;
} = {
  cm: "centimeter",
  ft: "feet",
  in: "inch",
  m: "meter",
};

export const mapperWeightUnitShortForm: Record<WeightUnit, WeightUnitFullName> =
  {
    kg: "kilogram",
    lbs: "pound",
  };

export const DIMENSION_PRESETS: {
  [key in LoadType]: {
    [key in UnitSystem]: {
      height: null | number;
      length: null | number;
      width: null | number;
    };
  };
} = {
  colis: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
  full_truck: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
  old: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
  palletized_80_120: {
    imperial: {
      height: null,
      length: roundValue(convertCmToIn(120)),
      width: roundValue(convertCmToIn(80)),
    },
    metric: {
      height: null,
      length: 120,
      width: 80,
    },
  },
  palletized_100_120: {
    imperial: {
      height: null,
      length: roundValue(convertCmToIn(120)),
      width: roundValue(convertCmToIn(100)),
    },
    metric: {
      height: null,
      length: 120,
      width: 100,
    },
  },
  palletized_specific: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
  solid_box: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
  vrac: {
    imperial: {
      height: null,
      length: null,
      width: null,
    },
    metric: {
      height: null,
      length: null,
      width: null,
    },
  },
};

export const mapLoadTypeToString: { [key in LoadType]: string } = {
  colis: "Packages",
  full_truck: "Full Truck",
  old: "Old",
  palletized_80_120: "Pallet (120x80)",
  palletized_100_120: "Pallet (120x100)",
  palletized_specific: "Pallet (specific)",
  solid_box: "Rigid Crate",
  vrac: "Bulk",
};

export const validateLoadForUpdate = (l: LoadOvrutilsForm): boolean => {
  if (
    [
      "colis",
      "palletized_80_120",
      "palletized_100_120",
      "palletized_specific",
      "solid_box",
    ].includes(l.loadType)
  ) {
    if (
      isNotDefined(l.unitHeight) ||
      isNotDefined(l.unitLength) ||
      isNotDefined(l.unitWidth) ||
      isNotDefined(l.unitWeight) ||
      isNotDefined(l.unitNumber)
    ) {
      return false;
    }
  }
  if (l.loadType === "vrac") {
    const totalVolumeIsNull =
      isNotDefined(l.totalVolume) || l.totalVolume === 0;
    const totalWeightIsNull =
      isNotDefined(l.totalWeight) || l.totalWeight === 0;

    if (totalVolumeIsNull || totalWeightIsNull) {
      return false;
    }
  }

  return true;
};

export const validateLoad = (l: LoadOvrutilsForm): boolean => {
  if (
    [
      "colis",
      "palletized_80_120",
      "palletized_100_120",
      "palletized_specific",
      "solid_box",
    ].includes(l.loadType)
  ) {
    if (
      isNotDefined(l.unitHeight) ||
      isNotDefined(l.unitLength) ||
      isNotDefined(l.unitWidth) ||
      isNotDefined(l.unitWeight) ||
      isNotDefined(l.unitNumber)
    ) {
      return false;
    }
  }
  if (l.loadType === "vrac") {
    const totalVolumeIsNull =
      isNotDefined(l.totalVolume) || l.totalVolume === 0;
    const totalWeightIsNull =
      isNotDefined(l.totalWeight) || l.totalWeight === 0;

    if (totalVolumeIsNull || totalWeightIsNull) {
      return false;
    }
  }
  if (!l.unitSystem && (!l.weightUnit || !l.volumeUnit)) {
    return false;
  }

  return true;
};

export const isPallet = (load: { loadType: LoadType }) => {
  return [
    "palletized_80_120",
    "palletized_100_120",
    "palletized_specific",
  ].includes(load.loadType);
};

export const countPallets = (load: LoadOvrutils): number => {
  if (!isPallet(load)) {
    return 0;
  }
  if (isNotDefined(load.unitNumber) || load.unitNumber <= 0) {
    throw new OvrseaDataError(
      `Standard load missing feature unitNumber: ${JSON.stringify(
        load,
        null,
        2,
      )}`,
    );
  }

  return load.unitNumber;
};

export const countTotalPallets = (loadArray: LoadOvrutils[]): number =>
  sumBy(loadArray, (load: LoadOvrutils) => countPallets(load));

export const countTotalLoads = (loadArray: LoadOvrutils[]): number =>
  sumBy(loadArray, (e) => e.unitNumber ?? 0);

export const calculateTotalVolumeInM3 = (
  loadArray: LoadOvrutilsWithUnits[],
  exactValue?: boolean,
): number => {
  return computeTotalVolume({
    loads: loadArray,
    roundedValue: !exactValue,
    targetUnit: "meter",
  });
};

export const calculateTotalWeightInKg = (
  loadArray: LoadOvrutilsWithUnits[],
  exactValue?: boolean,
) => {
  return computeTotalWeight({
    loads: loadArray,
    roundedValue: !exactValue,
    targetUnit: "kilogram",
  });
};

export const computeTotalDensity = (loads: LoadOvrutilsWithUnits[]) => {
  const totalWeightInKg = computeTotalWeight({ loads, targetUnit: "kilogram" });
  const totalVolumeInM3 = computeTotalVolume({ loads, targetUnit: "meter" });

  return roundValue(totalWeightInKg / totalVolumeInM3);
};

export const calculateTotalQuantity = (
  loads: LoadOvrutils[],
  exactValue?: boolean,
) => {
  const totalQuantity = sumBy(loads, (e) => e.unitNumber ?? 0);

  return exactValue ? totalQuantity : roundValue(totalQuantity);
};

const roundValueToHalfOfDecimal = (value: number) => {
  const roundedValue = roundValue(value);

  const modulo = roundedValue % 0.5;

  if (modulo === 0) {
    return roundedValue;
  }

  return roundedValue + (0.5 - modulo);
};

export const calculateTotalTaxableWeight: CalculateTotalSpecificUnit = (
  loadsArrayOrTotalWeight?: LoadOvrutilsWithUnits[] | null | number,
  totalVolume?: null | number,
  unitSystem?: UnitSystem,
) => {
  if (Array.isArray(loadsArrayOrTotalWeight)) {
    const loadsArray = loadsArrayOrTotalWeight;

    return roundValueToHalfOfDecimal(
      Math.max(
        calculateTotalVolumeInM3(loadsArray, true) / 6,
        calculateTotalWeightInKg(loadsArray, true) / 1000,
      ) * 1000,
    );
  }
  if (
    isNotDefined(loadsArrayOrTotalWeight) ||
    loadsArrayOrTotalWeight === 0 ||
    isNotDefined(totalVolume) ||
    totalVolume === 0
  ) {
    return null;
  }

  const totalWeightInKg =
    !unitSystem || unitSystem === "metric"
      ? loadsArrayOrTotalWeight
      : convertLbsToKg(loadsArrayOrTotalWeight);

  const totalVolumeInM3 =
    !unitSystem || unitSystem === "metric"
      ? totalVolume
      : convertCbfToCbm(totalVolume);

  return roundValueToHalfOfDecimal(
    Math.max(totalVolumeInM3 / 6, totalWeightInKg / 1000) * 1000,
  );
};

export const calculateWM1: CalculateTotalSpecificUnit = (
  loadsArrayOrTotalWeightInKg?: LoadOvrutilsWithUnits[] | null | number,
  totalVolumeInM3?: null | number,
) => {
  if (Array.isArray(loadsArrayOrTotalWeightInKg)) {
    const loadsArray = loadsArrayOrTotalWeightInKg;

    return roundValue(
      Math.max(
        calculateTotalWeightInKg(loadsArray) / 1000,
        calculateTotalVolumeInM3(loadsArray),
        1,
      ),
    );
  }
  const totalWeightInKg = loadsArrayOrTotalWeightInKg;

  if (
    isNotDefined(totalWeightInKg) ||
    totalWeightInKg <= 0 ||
    isNotDefined(totalVolumeInM3) ||
    totalVolumeInM3 <= 0
  ) {
    return null;
  }

  return roundValue(Math.max(totalWeightInKg / 1000, totalVolumeInM3, 1));
};

export const calculateWM2: CalculateTotalSpecificUnit = (
  loadsArrayOrTotalWeightInKg?: LoadOvrutilsWithUnits[] | null | number,
  totalVolumeInM3?: null | number,
) => {
  if (Array.isArray(loadsArrayOrTotalWeightInKg)) {
    const loadsArray = loadsArrayOrTotalWeightInKg;

    return roundValue(
      Math.max(
        calculateTotalWeightInKg(loadsArray) / 333,
        calculateTotalVolumeInM3(loadsArray),
        1,
      ),
    );
  }
  const totalWeightInKg = loadsArrayOrTotalWeightInKg;

  if (isNotDefined(totalWeightInKg) || isNotDefined(totalVolumeInM3)) {
    return null;
  }

  return roundValue(Math.max(totalWeightInKg / 333, totalVolumeInM3, 1));
};

export const loadsToString = (
  loads: { loadType: EnumOrLiteral<LoadType> }[],
  separator = "\n",
): null | string => {
  const firstLoad = first(loads);

  if (!firstLoad) {
    return null;
  }
  if (
    loads.every((l) => l.loadType === "vrac" || l.loadType === "full_truck")
  ) {
    return mapLoadTypeToString[firstLoad.loadType];
  }

  return Object.entries(groupBy(loads, (l) => l.loadType))
    .map(
      (keyValue) =>
        `${keyValue[1].length} x ${
          mapLoadTypeToString[keyValue[0] as LoadType]
        }`,
    )
    .join(separator);
};

export const doesLoadHaveSpecificSize = (load: {
  unitHeight?: null | number;
  unitLength?: null | number;
  unitWidth?: null | number;
}) => {
  const loadHaveSpecificLength =
    isDefined(load.unitLength) && load.unitLength > 200;
  const loadHaveSpecificWidth =
    isDefined(load.unitWidth) && load.unitWidth > 200;
  const loadHaveSpecificHeight =
    isDefined(load.unitHeight) && load.unitHeight > 160;

  return (
    loadHaveSpecificLength || loadHaveSpecificWidth || loadHaveSpecificHeight
  );
};

export const doLoadsHaveSpecificSize = (
  loads: {
    unitHeight?: null | number;
    unitLength?: null | number;
    unitWidth?: null | number;
  }[],
) => {
  return loads.some(doesLoadHaveSpecificSize);
};

const haveNotSameResult = (change: LoadsChange) => {
  return change.resultA !== change.resultB;
};

export const findLoadsTotalsChanges = (
  loadsA: LoadOvrutilsWithUnits[],
  loadsB: LoadOvrutilsWithUnits[],
) => {
  const changes: LoadsChange[] = [
    {
      resultA: calculateTotalWeightInKg(loadsA),
      resultB: calculateTotalWeightInKg(loadsB),
      type: "totalWeight",
    },
    {
      resultA: calculateTotalQuantity(loadsA),
      resultB: calculateTotalQuantity(loadsB),
      type: "totalQuantity",
    },
    {
      resultA: calculateTotalVolumeInM3(loadsA),
      resultB: calculateTotalVolumeInM3(loadsB),
      type: "totalVolume",
    },
    {
      resultA: calculateTotalTaxableWeight(loadsA),
      resultB: calculateTotalTaxableWeight(loadsB),
      type: "taxableWeight",
    },
  ];

  return changes.filter(haveNotSameResult);
};

export const isLoadNonStackable = ({
  freightMethod,
  load,
}: {
  freightMethod: FreightMethodOvrUtils;
  load: LoadOvrutils;
}) => {
  if (freightMethod === "ocean") {
    return (
      load.nonStackable &&
      isDefined(load.unitHeight) &&
      load.unitHeight < MAX_PALLET_HEIGHT_FOR_NON_STACKABLE_OCEAN_IN_CM
    );
  }

  return load.nonStackable;
};

export const applyGenericHeightOnLoad = <Load extends LoadOvrutils>(
  load: Load,
): Load => {
  return {
    ...load,
    unitHeight: GENERIC_NON_STACKABLE_PALLET_HEIGHT_IN_CM,
  };
};

export const applyGenericHeightOnNonStackableLoads = <
  Load extends LoadOvrutils,
>({
  freightMethod,
  loads,
}: {
  freightMethod: FreightMethodOvrUtils;
  loads: Load[];
}): Load[] => {
  return loads.map((load) =>
    isLoadNonStackable({ freightMethod, load })
      ? applyGenericHeightOnLoad(load)
      : load,
  );
};

export const computeNumberOfPalletsByVolume = (volumeInM3: number) => {
  return Math.ceil(volumeInM3 / GENERIC_PALLET_VOLUME_IN_M3);
};

export const computeNumberOfPalletsByWeight = (weightInKg: number) => {
  return Math.ceil(weightInKg / GENERIC_PALLET_WEIGHT_IN_KG);
};

export const computeNumberOfPalletsByVolumeOrWeight = ({
  volumeInM3,
  weightInKg,
}: {
  volumeInM3: number;
  weightInKg: number;
}) => {
  const numberOfPalletsByVolume = computeNumberOfPalletsByVolume(volumeInM3);
  const numberOfPalletsByWeight = computeNumberOfPalletsByWeight(weightInKg);

  return Math.max(numberOfPalletsByVolume, numberOfPalletsByWeight);
};

const createGenericPallet = ({
  hazardous,
  hazardousDetails,
  lithium,
  magnetic,
  nonStackable,
  numberOfPallets,
  refrigerated,
  totalWeightInKg,
}: {
  hazardous: boolean;
  hazardousDetails: string;
  lithium: boolean;
  magnetic: boolean;
  nonStackable: boolean;
  numberOfPallets: number;
  refrigerated: boolean;
  totalWeightInKg: number;
}): { palletized?: boolean } & LoadOvrutilsWithUnits => {
  return {
    hazardous,
    hazardousDetails,
    lithium,
    loadType: "palletized_80_120",
    magnetic,
    nonStackable,
    palletized: true,
    refrigerated,
    unitHeight: GENERIC_PALLET_HEIGHT_IN_CM,
    unitLength: GENERIC_PALLET_LENGTH_IN_CM,
    unitNumber: numberOfPallets,
    unitWeight: totalWeightInKg / numberOfPallets,
    unitWidth: GENERIC_PALLET_WIDTH_IN_CM,
    volumeUnit: "cm",
    weightUnit: "kg",
  };
};

export const palletizeColis = (loads: LoadOvrutilsWithUnits[]) => {
  if (loads.length === 0) {
    return [];
  }

  const totalVolumeInM3 = calculateTotalVolumeInM3(loads);
  const totalWeightInKg = calculateTotalWeightInKg(loads);

  const numberOfPallets = computeNumberOfPalletsByVolumeOrWeight({
    volumeInM3: totalVolumeInM3,
    weightInKg: totalWeightInKg,
  });

  return [
    createGenericPallet({
      hazardous: loads.some((load) => load.hazardous),
      hazardousDetails: loads.map((load) => load.hazardousDetails).join("; "),
      lithium: loads.some((load) => load.lithium),
      magnetic: loads.some((load) => load.magnetic),
      nonStackable: loads.some((load) => load.nonStackable),
      numberOfPallets,
      refrigerated: loads.some((load) => load.refrigerated),
      totalWeightInKg,
    }),
  ];
};

export const palletizeVrac = (loads: LoadOvrutilsWithUnits[]) => {
  if (loads.length === 0) {
    return [];
  }

  const totalVolumeInM3 = calculateTotalVolumeInM3(loads);
  const totalWeightInKg = calculateTotalWeightInKg(loads);

  const numberOfPallets = computeNumberOfPalletsByVolume(totalVolumeInM3);

  return [
    createGenericPallet({
      hazardous: loads.some((load) => load.hazardous),
      hazardousDetails: loads.map((load) => load.hazardousDetails).join("; "),
      lithium: loads.some((load) => load.lithium),
      magnetic: loads.some((load) => load.magnetic),
      nonStackable: loads.some((load) => load.nonStackable),
      numberOfPallets,
      refrigerated: loads.some((load) => load.refrigerated),
      totalWeightInKg,
    }),
  ];
};

export const palletizeLoads = (
  loads: LoadOvrutilsWithUnits[],
): ({ palletized?: boolean } & LoadOvrutilsWithUnits)[] => {
  const vracLoads = loads.filter((load) => load.loadType === "vrac");
  const colisLoads = loads.filter((load) => load.loadType === "colis");
  const standardLoads = loads.filter(
    (load) => !["colis", "vrac"].includes(load.loadType),
  );

  const palletizedColis = palletizeColis(colisLoads);
  const palletizedVrac = palletizeVrac(vracLoads);

  return [...standardLoads, ...palletizedColis, ...palletizedVrac];
};

export const addUnitsToLoads = (
  loads: ({ unitSystem: UnitSystem } & LoadOvrutils)[],
): LoadOvrutilsWithUnits[] => {
  return loads.map((load) => {
    const units = UNIT_SYSTEMS[load.unitSystem];

    return { ...load, ...units };
  });
};

export const determineUnitSystemFromLoads = (
  loads: { volumeUnit?: null | string; weightUnit?: null | string }[],
): UnitSystem => {
  if (!loads.length) {
    return "metric";
  }

  const isImperial = loads.every(
    ({ volumeUnit, weightUnit }) => weightUnit === "lbs" && volumeUnit === "in",
  );
  const isMetric = loads.every(
    ({ volumeUnit, weightUnit }) => weightUnit === "kg" && volumeUnit === "cm",
  );

  if (isImperial) {
    return "imperial";
  } else if (isMetric) {
    return "metric";
  }

  throw new Error("Loads are in different unit systems or without units");
};

export const convertLoadsInMetricSystem = <
  T extends {
    loadType: LoadType;
    totalVolume?: null | number;
    totalWeight?: null | number;
    unitHeight?: null | number;
    unitLength?: null | number;
    unitWeight?: null | number;
    unitWidth?: null | number;
    volumeUnit: VolumeUnit;
    weightUnit: WeightUnit;
  },
>(
  loads: T[],
) => {
  return loads.map((load) => {
    if (["full_truck", "vrac"].includes(load.loadType)) {
      const volumeInCcm = ["full_truck", "vrac"].includes(load.loadType)
        ? computeTotalVolume({ loads: [load], targetUnit: "centimeter" })
        : 0;
      const weightInKg = ["full_truck", "vrac"].includes(load.loadType)
        ? computeTotalWeight({ loads: [load], targetUnit: "kilogram" })
        : 0;

      return {
        ...load,
        totalVolume: volumeInCcm,
        totalWeight: weightInKg,
        volumeUnit: "cm",
        weightUnit: "kg",
      };
    }

    const unitHeighInCm =
      load.volumeUnit === "cm"
        ? load.unitHeight
        : roundValue(convertInToCm(load.unitHeight ?? 0));
    const unitWidthInCm =
      load.volumeUnit === "cm"
        ? load.unitWidth
        : roundValue(convertInToCm(load.unitWidth ?? 0));
    const unitLengthInCm =
      load.volumeUnit === "cm"
        ? load.unitLength
        : roundValue(convertInToCm(load.unitLength ?? 0));
    const unitWeightInKg =
      load.weightUnit === "kg"
        ? load.unitWeight
        : roundValue(convertLbsToKg(load.unitWeight ?? 0), 3);

    return {
      ...load,
      unitHeight: unitHeighInCm,
      unitLength: unitLengthInCm,
      unitWeight: unitWeightInKg,
      unitWidth: unitWidthInCm,
      volumeUnit: "cm",
      weightUnit: "kg",
    };
  });
};
