import { compact, maxBy, sumBy, uniqBy } from "lodash-es";
import { OVRSEA_AGENT_ID } from "../../operations/shipowners/shipownersConstants";
import type { DbPricingSteps } from "../../sharedTypes";
import type { ExchangeRateOvrutils } from "../pricesUtils/priceUtils";
import {
  calculateTotalPriceWithCurrency,
  type Currency,
} from "../pricesUtils/priceUtils";

type Shipowner = { country: null | string; id: string };
type FinalCheckPrice = {
  category: DbPricingSteps;
  isBilledAndSent: boolean;
  priceCurrency: Currency;
  priceName: string;
  shipowner: Shipowner | null;
  unitPrice: number;
};

export type FinalCheckSellingPrice = {
  category: DbPricingSteps;
  isBilledAndSent: boolean;
  priceCurrency: Currency;
  priceName: string;
  unitPrice: number;
};

export type { FinalCheckPrice as FinalCheckPriceForDetermineCustomsAdvanceFee };

type DetermineCustomsAdvanceFeeInput = {
  finalCheck: {
    exchangeRates: ExchangeRateOvrutils[];
    purchasePrices: FinalCheckPrice[];
    sellingPrices: FinalCheckSellingPrice[];
  };
  routing: {
    arrival: { harbor: { countryCode: null | string } | null } | null;
  };
};

export type DetermineCustomsAdvanceFeeResult =
  | {
      customsAdvanceFeePriceCents: number;
      isAlreadyPresent: boolean;
      isAlreadyPresentButWrongValue: boolean;
      isApplicable: true;
    }
  | {
      isApplicable: false;
    };

const determineCustomsShipownerIfApplicable = (
  allCustomsCategoryPrices: FinalCheckPrice[],
  routing: DetermineCustomsAdvanceFeeInput["routing"],
  exchangeRates: ExchangeRateOvrutils[],
): Shipowner | null => {
  if (routing.arrival?.harbor?.countryCode === "it") {
    return null;
  }

  const [firstCustomsPrice] = allCustomsCategoryPrices;

  if (allCustomsCategoryPrices.length === 0 || !firstCustomsPrice) {
    return null;
  }

  const targetCurrency = firstCustomsPrice.priceCurrency;

  const uniqueShipowners = uniqBy(
    compact(allCustomsCategoryPrices.map((price) => price.shipowner)),
    (shipowner) => shipowner.id,
  );

  if (uniqueShipowners.length === 0) {
    return null;
  }

  const shipownersWithTotalCustomsPrice = uniqueShipowners.map((shipowner) => {
    const shipownerCustomPrices = allCustomsCategoryPrices.filter(
      (price) => price.shipowner?.id === shipowner.id,
    );

    return {
      ...shipowner,
      totalCustomsPriceCents: calculateTotalPriceWithCurrency({
        exchangeRateArray: exchangeRates,
        priceArray: shipownerCustomPrices.map((price) => ({
          currency: price.priceCurrency,
          value: price.unitPrice * 100,
        })),
        targetCurrency,
      }),
    };
  });

  const customsShipowner = maxBy(
    shipownersWithTotalCustomsPrice,
    (shipowner) => shipowner.totalCustomsPriceCents,
  );

  if (!customsShipowner || customsShipowner.id === OVRSEA_AGENT_ID) {
    return null;
  }

  const totalCustomsPriceCents = sumBy(
    allCustomsCategoryPrices,
    (customsPrices) => customsPrices.unitPrice * 100,
  );

  if (totalCustomsPriceCents <= 0) {
    return null;
  }

  return customsShipowner;
};

const CUSTOMS_ADVANCE_FEE_PRICE_CHARACTERISTICS = {
  category: "arrival_fees",
  clientName: "Customs Advance Fee",
} as const;

const ADVANCE_FEE_PERCENTAGE_OF_CUSTOMS_TOTAL = { fr: 0.35, us: 3 };

const MINIMUM_ADVANCE_FEE_PRICE_CENTS = {
  fr: { currency: "EUR", priceCents: 35_00 },
  us: { currency: "USD", priceCents: 25_00 },
} as const;

const determineFeesDependingOnShipownerCountry = (country: null | string) => {
  if (country !== "fr" && country !== "us") {
    return null;
  }

  return {
    advanceFeeCurrency: MINIMUM_ADVANCE_FEE_PRICE_CENTS[country].currency,
    advanceFeePercentageOfCustomsTotal:
      ADVANCE_FEE_PERCENTAGE_OF_CUSTOMS_TOTAL[country],
    minimumAdvancePriceCents:
      MINIMUM_ADVANCE_FEE_PRICE_CENTS[country].priceCents,
  };
};

const isDifferentValue = (priceCents1: number, priceCents2: number) =>
  Math.abs(priceCents1 - priceCents2) > 1;

const determineCurrentAdvanceFeeProperties = ({
  advanceFeeCurrency,
  customsAdvanceFeeAppliedAmount,
  finalCheck,
}: {
  advanceFeeCurrency: "EUR" | "USD";
  customsAdvanceFeeAppliedAmount: number;
  finalCheck: DetermineCustomsAdvanceFeeInput["finalCheck"];
}) => {
  const customsPriceExistingLine = finalCheck.sellingPrices.find(
    (price) =>
      price.priceName ===
        CUSTOMS_ADVANCE_FEE_PRICE_CHARACTERISTICS.clientName &&
      price.category === CUSTOMS_ADVANCE_FEE_PRICE_CHARACTERISTICS.category,
  );

  const isAlreadyPresentButWrongValue =
    !!customsPriceExistingLine &&
    (isDifferentValue(
      customsPriceExistingLine.unitPrice * 100,
      customsAdvanceFeeAppliedAmount,
    ) ||
      customsPriceExistingLine.priceCurrency !== advanceFeeCurrency) &&
    !customsPriceExistingLine.isBilledAndSent;

  return {
    isAlreadyPresent: !!customsPriceExistingLine,
    isAlreadyPresentButWrongValue,
  };
};

export const determineCustomsAdvanceFee = ({
  finalCheck,
  routing,
}: DetermineCustomsAdvanceFeeInput): DetermineCustomsAdvanceFeeResult => {
  const customsPurchasePrices = finalCheck.purchasePrices.filter(
    (price) => price.category === "customs",
  );

  const customsShipowner = determineCustomsShipownerIfApplicable(
    customsPurchasePrices,
    routing,
    finalCheck.exchangeRates,
  );

  if (!customsShipowner) {
    return {
      isApplicable: false,
    };
  }

  const customsShipownerPurchasePrices = customsPurchasePrices.filter(
    (price) => price.shipowner?.id === customsShipowner.id,
  );

  const applicableCustomsFeesCharacteristics =
    determineFeesDependingOnShipownerCountry(customsShipowner.country);

  if (
    !applicableCustomsFeesCharacteristics ||
    customsShipownerPurchasePrices.length === 0
  ) {
    return {
      isApplicable: false,
    };
  }

  const {
    advanceFeeCurrency,
    advanceFeePercentageOfCustomsTotal,
    minimumAdvancePriceCents,
  } = applicableCustomsFeesCharacteristics;

  const totalCustomsShipownerPriceCents =
    calculateTotalPriceWithCurrency({
      exchangeRateArray: finalCheck.exchangeRates,
      priceArray: customsShipownerPurchasePrices.map((price) => ({
        currency: price.priceCurrency,
        value: price.unitPrice,
      })),
      targetCurrency: advanceFeeCurrency,
    }) * 100;

  const customsAdvanceFeeApplicableAmount =
    totalCustomsShipownerPriceCents *
    (advanceFeePercentageOfCustomsTotal / 100);

  const customsAdvanceFeeAppliedAmount = Math.max(
    minimumAdvancePriceCents,
    customsAdvanceFeeApplicableAmount,
  );

  const { isAlreadyPresent, isAlreadyPresentButWrongValue } =
    determineCurrentAdvanceFeeProperties({
      advanceFeeCurrency,
      customsAdvanceFeeAppliedAmount,
      finalCheck,
    });

  return {
    customsAdvanceFeePriceCents: customsAdvanceFeeAppliedAmount,
    isAlreadyPresent,
    isAlreadyPresentButWrongValue,
    isApplicable: !!customsShipowner,
  };
};
