import { reduce } from "lodash-es";
import { strcmp } from "@ovrsea/ovrutils";
import type { CSSProperty } from "../../utils/types/css";
import type { Theme } from "../../theme/theme";
import type { StyleSystemKeys } from "./system";

type PropertyTransformer = CSSProperty | CSSProperty[];

type ThemeCategory = Theme[StyleSystemKeys];

type Injector = <
  Accessor extends ThemeCategory = ThemeCategory,
  Scale extends keyof Accessor = keyof Accessor,
>(
  accessor: Accessor,
  scale: Scale,
  transformer: PropertyTransformer,
) => Partial<Record<CSSProperty, any>>;

const createInjector: Injector = (accessor, scale, transformer) => {
  if (typeof transformer === "string") {
    return {
      [transformer]: accessor[scale],
    };
  }

  return reduce(
    transformer.sort((a, b) => strcmp(a, b)),
    (props, prop) => {
      return {
        ...props,
        [prop]: accessor[scale],
      };
    },
    {},
  );
};

type Injectors = {
  [x in StyleSystemKeys]: (
    transformers: PropertyTransformer,
  ) => <Value extends keyof ThemeCategory>(
    theme: Theme,
    value: Value,
  ) => Partial<Record<CSSProperty, Value>>;
};

export const injectors: Injectors = {
  spacing: (property) => (theme, value) =>
    createInjector(theme.spacing, value, property),
};
