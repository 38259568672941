import { noop } from "lodash-es";
import type { DateFormat } from "./date/formats";
import { dayjs } from "./ovrDayjs";
import {
  calculateDiffBetweenTwoDates,
  calculateDiffInDaysBetweenTwoDates,
} from "./time";
import { isNotDefined } from "./other/isNotDefined";

/* can't import from @ovrsea/i18n without enabling jsx */
export type SupportedLocales = "en" | "es" | "fr" | "it";

export type Time = { hours: null | number; minutes: null | number };

type FormatDateArgs = {
  date: dayjs.Dayjs;
  //MIEUX TYPER CA, AVEC UNE ENUM DES CAS POSSIBLE
  format: DateFormat;
  locale?: SupportedLocales;
  timezone?: null | string;
};

const convertDateTimeTimezoneToUnix = (
  date?: Date | null,
  time?: Time | null,
  timezone?: null | string,
) => {
  if (!date) {
    return null;
  }
  //get day as string from date seen as utc
  const dayjsDate = dayjs.utc(date).format("YYYY-MM-DD");
  const hours = time?.hours ?? 0;
  const minutes = time?.minutes ?? 0;
  //consider date and time as read in the requested timezone, get unix corresponding to this event
  const dateInUTC = dayjs
    // user local timezone by default
    .tz(`${dayjsDate} ${hours}:${minutes}`, timezone ?? undefined)
    .unix();

  return dateInUTC;
};

const convertUnixToDateTimeInTimezone = (
  timestamp: null | number | undefined,
  timezone?: null | string | undefined,
) => {
  if (isNotDefined(timestamp)) {
    return { day: null, time: { hours: null, minutes: null } };
  }
  // user local timezone by default
  // user local timezone by default
  const dateInTimezone = dayjs.unix(timestamp).tz(timezone ?? undefined);

  const UTCDate = new Date(
    Date.UTC(
      dateInTimezone.get("year"),
      dateInTimezone.get("month"),
      dateInTimezone.get("date"),
      dateInTimezone.get("hour"),
      dateInTimezone.get("minute"),
    ),
  );

  return {
    day: UTCDate,
    time: {
      hours: dateInTimezone.get("hour"),
      minutes: dateInTimezone.get("minute"),
    },
  };
};

const convertISOLocalDateToUnix = (
  stringDate: string,
  timezone?: null | string,
): number => {
  // !! IMPORTANT - ISO Without TZ format : "YYYY-MM-DDTHH:mm:ss", considered as a local date
  // user local timezone by default
  return dayjs.tz(stringDate, timezone || undefined).unix();
};

const doesDateHasTime = (date: dayjs.Dayjs) => {
  return Boolean(date.hour() || date.minute());
};

const convertUnixToDate = (
  unix: number,
  timezone?: null | string,
): dayjs.Dayjs => {
  //Throw warning if no timezone provided. UTC here only to catch edge cases during migration process.
  // user local timezone by default
  return dayjs.unix(unix).tz(timezone || undefined);
};

export const convertNullableUnixToDate = (
  unixTs: null | number,
): dayjs.Dayjs | null => {
  if (!unixTs) {
    return null;
  }

  return convertUnixToDate(unixTs);
};

export const convertNullableDateToUnix = (date: Date | null): null | number => {
  if (!date) {
    return null;
  }

  return dayjs(date).unix();
};

const doesUnixDateHasTime = (unix: number, timezone?: null | string) => {
  // user local timezone by default
  const date = convertUnixToDate(unix, timezone || undefined);

  return Boolean(date.hour() || date.minute());
};

const formatDate = ({
  date,
  format,
  locale = "fr",
  onError = noop,
  timezone,
}: {
  onError?: (err: Error, args: FormatDateArgs) => any;
} & FormatDateArgs) => {
  // we have to stringify and parse because of a dayjs bug. See:
  // https://github.com/iamkun/dayjs/issues/1219

  const stringifiedDate = date
    .tz(timezone || undefined)
    .format("YYYY-MM-DD HH:mm");

  try {
    return dayjs(stringifiedDate, "YYYY-MM-DD HH:mm")
      .locale(locale)
      .format(format[locale]);
  } catch (err: any) {
    console.log("Error", err);
    console.log("Args:", { date, format, locale, timezone });
    onError(err, { date, format, locale, timezone });
    throw err;
  }
};

const formatUnixDate = ({
  format,
  locale = "fr",
  onError = noop,
  timezone,
  unix,
}: {
  format: DateFormat;
  locale?: SupportedLocales;
  onError?: (err: Error, args: FormatDateArgs) => any;
  timezone?: null | string;
  unix: number;
}) => {
  //Throw warning if no timezone provided. UTC here only to catch edge cases during migration process.
  // user local timezone by default
  const date = dayjs.unix(unix);

  return formatDate({
    date,
    format,
    locale,
    onError,
    // user local timezone by default
    timezone: timezone || undefined,
  });
};

type ComputeDelayParams = {
  newTimestamp: number;
  oldTimestamp: number;
  timezone?: string;
};

type Delay = {
  delay: number;
  delayUnit: "days" | "hours";
  isDelayed: boolean;
  newDate: dayjs.Dayjs;
};

const computeDelay = ({
  newTimestamp,
  oldTimestamp,
  timezone,
}: ComputeDelayParams): Delay => {
  const newDate = convertUnixToDate(newTimestamp, timezone);
  const oldDate = convertUnixToDate(oldTimestamp, timezone);

  if (newDate.isSame(oldDate, "date")) {
    const delayInHours = calculateDiffBetweenTwoDates({
      firstDate: oldDate.toDate(),
      secondDate: newDate.toDate(),
    });

    return {
      delay: delayInHours,
      delayUnit: "hours",
      isDelayed: delayInHours > 0,
      newDate,
    };
  }

  const delayInDays = calculateDiffInDaysBetweenTwoDates({
    newTimestamp: newDate.toDate().getTime(),
    oldTimestamp: oldDate.toDate().getTime(),
    timezone,
  });

  return {
    delay: delayInDays,
    delayUnit: "days",
    isDelayed: delayInDays > 0,
    newDate,
  };
};

type FormatDelayParams = {
  delay: number;
  isDelayed: boolean;
};

const formatDelay = ({ delay, isDelayed }: FormatDelayParams) => {
  const absoluteDelay = Math.abs(delay);

  const isPlural = absoluteDelay > 1;
  const sign = isDelayed ? "+" : "-";

  const formattedDelay = `${sign}${absoluteDelay}`;

  return {
    formattedDelay,
    isPlural,
  };
};

const nextMondayIfWeekend = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const DAYJS_SATURDAY = 6;
  const DAYJS_SUNDAY = 0;

  if (date.day() === DAYJS_SATURDAY) {
    return date.add(2, "days");
  }
  if (date.day() === DAYJS_SUNDAY) {
    return date.add(1, "days");
  }

  return date;
};

const calculateNextBusinessDaysInXdays = ({
  date,
  daysToAdd = 0,
  timezone = "UTC",
}: {
  date: Date;
  daysToAdd?: number;
  timezone?: string;
}): Date => {
  const dateWithXAddedDays = dayjs.tz(date, timezone).add(daysToAdd, "day");

  const nextBusinessDayAfterXDays = nextMondayIfWeekend(dateWithXAddedDays);

  return nextBusinessDayAfterXDays.startOf("day").utc().toDate();
};

export {
  calculateNextBusinessDaysInXdays,
  computeDelay,
  convertDateTimeTimezoneToUnix,
  convertISOLocalDateToUnix,
  convertUnixToDate,
  convertUnixToDateTimeInTimezone,
  doesDateHasTime,
  doesUnixDateHasTime,
  formatDate,
  formatDelay,
  formatUnixDate,
};
