import { escapeRegExp } from "lodash-es";

/**
 * @description: This function compares the ovrsea name of a price or a rate and checks if it's the same as a specific rate name.
 * For instance, a price ovrsea name could be "Freight (40 standard)" and we might want to know if this is a freight price (ie containing "Freight")
 */
export const PROFIT_SHARE_OVRSEA_NAME = "Profit Share";

export const compareOvrseaNames = (
  ovrseaName1: string,
  ovrseaName2: string,
): boolean => {
  const regex1 = new RegExp(`\\b${escapeRegExp(ovrseaName1)}\\b`, "i");
  const regex2 = new RegExp(`\\b${escapeRegExp(ovrseaName2)}\\b`, "i");

  return regex1.test(ovrseaName2) || regex2.test(ovrseaName1);
};
