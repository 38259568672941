import React from "react";
import { ariaAttribute } from "../../utils/attributes";
import type { SystemProps } from "../../utils/types/system";
import { polymorphic } from "../../utils/ref";
import { styled } from "../../utils/system/factory";
import { iconsMap } from "./Icons/Icons";

export type IconName = keyof typeof iconsMap;

export type IconProps = SystemProps<"svg"> & {
  isDecorative?: boolean;
  name: IconName;
};

const StyledIconBox = styled<"svg", Pick<IconProps, "isDecorative">>("svg", {
  base: ({ isDecorative, theme }) => ({
    display: "inline-block",
    fill: isDecorative ? theme.colors.neutral["40"] : "currentcolor",
    flexShrink: 0,
    fontSize: "1rem",
    height: "1em",
    lineHeight: "1em",
    verticalAlign: "middle",
    width: "1em",
  }),
});

const viewbox = "0 0 16 16";

const Icon = polymorphic<"svg", IconProps>(({ name, ...rest }, ref) => (
  <StyledIconBox
    aria-hidden={ariaAttribute(true)}
    focusable={false}
    ref={ref}
    viewBox={viewbox}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {iconsMap[name]}
  </StyledIconBox>
));

Icon.displayName = "Icon";

export { Icon };
